(function($) {

	'use strict';

     // Hero slider
    var swiper = new Swiper(".hero-slider .swiper-container", {
        loop: true,
        speed: 2000,
        effect: 'fade',
        slidesPerView: 1,
        autoplay: true,
        pagination: false,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    // Testimonial Slider
    var swiper = new Swiper(".section-quotes .swiper-container", {
        direction: "horizontal",
        loop: true,
        effect: 'slide',
        centeredSlides: false,
        slidesPerView: 1,
        spaceBetween: 40,
        pagination: false,
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 40,
            }
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        }
    });

    // Scroll based on hash
    if(window.location.hash) {
        var hash = window.location.hash.substring(1);
        if(hash){
            $('html,body').animate({scrollTop: $('#' + hash).offset().top - $('.site-header').height()},'fast');
            history.pushState('', document.title, window.location.pathname);
        }
    }

    // Scroll to accordian
    $('.panel-group').on('shown.bs.collapse', function() {
        var panel = $(this).find('.in');
        $('html, body').animate({
            scrollTop: panel.offset().top - ($('.site-header').height() + 115)
        }, 'slow');
    });

	// Load backgorund image from data attribute
	loadBackground();
	$(window).on('resize orientationchange', loadBackground);
	function loadBackground(){
		if ($('[data-background]').length > 0) {
			$('[data-background]').each(function() {
				var $background, $backgroundmobile, $this;
				$this = $(this);
				$background = $this.attr('data-background');
				$backgroundmobile = $this.attr('data-background-mobile');
				if ($this.attr('data-background').substr(0, 1) === '#') {
					return $this.css('background-color', $background);
				} else if ($this.attr('data-background-mobile') && device.mobile()) {
					return $this.css('background-image', 'url(' + $backgroundmobile + ')');
				} else {
					return $this.css('background-image', 'url(' + $background + ')');
				}
			});
		}
	}
	
	// Counter
	$('.counter span').counterUp({
        delay: 10,
        time: 1000
    });
	
	//Scroll to Top
	$(window).scroll(function () {
		if ($(this).scrollTop() >= 100) {
			$('.scroll-to-top').fadeIn(200);
		} else {
			$('.scroll-to-top').fadeOut(200);
		}
	});
	$('.scroll-to-top').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
	});
	
	// Get current URL path and assign 'active' class
	var url = window.location;
	$('.nav > li > a[href="'+url+'"]').parent().addClass('active');
	$('.nav a').filter(function () {
		return this.href == url;
	}).parent().addClass('active').parent().parent().addClass('active');

	/* Product filter */
	var $filters = $('.filter-links [data-filter]'),
		$boxes = $('.filter-items [data-category]');

	/*var hash = window.location.hash.substr(1);
	if(hash){
		$filters.removeClass('active');
		$('.filter-links [data-filter="'+hash+'"]').addClass('active');
		$boxes.removeClass('animated').fadeOut().promise().done(function() {
			$boxes.filter(function(i, el) {
				return el.dataset.category.split(',').indexOf(hash) !== -1;
			}).addClass('animated').fadeIn('fast');
		});
	}*/

	$filters.on('click', function(e) {
		e.preventDefault();
		var $this = $(this);

		$filters.removeClass('active');
		$this.addClass('active');

		var $filterColor = $this.attr('data-filter');
		if ($filterColor == 'all') {
			$boxes.removeClass('animated').fadeOut().promise().done(function() {
				$boxes.addClass('animated').fadeIn('fast');
			});
		} else {
			$boxes.removeClass('animated').fadeOut().promise().done(function() {
				$boxes.filter(function(i, el) {
					return el.dataset.category.split(',').indexOf($filterColor) !== -1;
				}).addClass('animated').fadeIn('fast');
			});
		}
	});

	/* Contact form */
	$('#form-contact').validate({
		rules: {
			'fc-name': {
				required: true
			},
			'fc-email': {
				required: true,
				email: true
			},
			'fc-phone': {
				required: true,
				digits: true
			},
			'fc-subject':{
				required: true
			}
		},
		highlight: function(element) {
			$(element).parent().is('.has-success, .has-error') ? 
			$(element).parent().removeClass('has-success').addClass('has-error') : 
			$(element).wrap('<span class="has-error"></span>');
		},
		unhighlight: function(element) {
			$(element).parent().is('.has-success, .has-error') ? 
			$(element).parent().removeClass('has-error').addClass('has-success') : 
			$(element).wrap('<span class="has-success"></span>');
		},
		errorElement: 'span',
		errorClass: 'help-block',
		errorPlacement: function(error, element) {},
		submitHandler: function(form) {
			var submitBtn = $(form).find("button[type=submit]");
			var submitBtnText = submitBtn.html();
			submitBtn.html("Please wait...").attr('disabled', true);
			$.ajax({
				url: form.action,
				type: form.method,
				dataType: "json",
				data: $(form).serialize(),
				success: function(response) {
					submitBtn.html(submitBtnText).attr('disabled', false);
					$('#result-contact').html('<div class="alert alert-'+response.type+'"><a href="#" class="close" data-dismiss="alert" aria-label="close" title="close">×</a>'+response.message+'</div>');
					if(response.type == "success"){
						$(form).find('.has-success').removeClass('has-success');
						$(form)[0].reset();
					}
				}            
			});
		}
	});
})(jQuery);